/* ------------------------------------------- settings page - color scheme selector css  */
.settings .radio {
    width: 25%;
    max-width: 200px;
    border: 1px solid #ccc;
    display: inline-block;
    padding: 10px;
    margin: 0 5% 5% 0;
    min-height: 110px;
    position: relative;
}

.settings .radio input[type="radio"] {
    margin-right: 10px;
    margin-top: 1px;
    float: left;
    display: inline-block;
    width: auto !important;
}

.settings .radio .color-scheme-1-picker {
    position: absolute;
    background: #ff6028;
    width: 100%;
    height: 50px;
    bottom: 0;
    left: 0;
}

.settings .radio .color-scheme-2-picker {
    position: absolute;
    background: #00b374;
    width: 100%;
    height: 50px;
    bottom: 0;
    left: 0;
}

.settings .radio .color-scheme-3-picker {
    position: absolute;
    background: #288bf3;
    width: 100%;
    height: 50px;
    bottom: 0;
    left: 0;
}

.kb-table .kb-col-name a {
    font-weight: bold;
    color: $primary;
}

.sub-category-link {
  font-weight: bold;
  color: #03B274!important;
}

.category-view.kb-table {
    background: $gray-100;
}

.subcategory-view.kb-table {
    background: $white;
    transition: 0.3s;
    font-weight: 400;

    .kb-col-name {
        @include media-breakpoint-up(lg) {
            text-indent: 1rem;
        }

    }

}

.kb-totals-view.kb-table {
    background: $gray-200;
    font-weight: bold;
}

.scroll-sticky.fixed-nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
}



.above-budget {
    border-top: 2px solid $warning;
}
