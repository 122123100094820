@font-face {
    font-family: 'Ubuntu';
    src: url('../../fonts/Unbutu/Ubuntu-Regular.ttf');
}
.dots-hover {
    display: none;
    position: absolute;
    left: -58px;
    top: 30px;
    z-index: 9;
    margin: 0;
    padding: 10px 0 5px;
    list-style-type: none;
    background: $secondary;
    color:$gray-100;
    min-width: 150px;
    -webkit-box-shadow: 1px 2px 5px 0px rgba(102, 102, 102, 1);
    -moz-box-shadow: 1px 2px 5px 0px rgba(102, 102, 102, 1);
    box-shadow: 1px 2px 5px 0px rgba(102, 102, 102, 1);
    border-radius: 5px 5px 5px 5px;
    -moz-border-radius: 5px 5px 5px 5px;
    -webkit-border-radius: 5px 5px 5px 5px;
    border: 0px solid $secondary;
}

.dots-hover li {
        padding: 0.25rem 1rem;
}

.dots-hover li a {
    color: $white;
}

.dots-hover:before {
    content: "";
    position: absolute;
    display: inline-block;
    width: 0;
    height: 0;
    left: 60px;
    top: -10px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent $secondary transparent;
}

.dots-cta .dots {
        opacity: 0.5;
        cursor: pointer;
}

.dots-cta.active + .dots-hover {
    display: block;
}

.dots-cta.active + .dots-hover li {
    cursor: pointer;
}

.dots-cta.active .dots {
    color: $secondary;
}

.pink {
       border-top:2px  solid $primary;
       }

$fontSize2: 1rem;
$fontSize3: 1.16rem; //14px
$fontSize4: 1.25rem; //14px
$fontSize5: 1.5rem; //18px
$fontSize6: 1.67rem; //20px
$fontSize7: 1.85rem; //22px
$fontSize8: 2.0rem; //24px

$ghost: #B2B2C0;
$quartz: #E2E2E4;
$whisper: #ECECEC;