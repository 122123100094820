.urlGroup {
  width: 100%;
  display: inline-block;
  vertical-align: bottom;
}

.urlGroup input {
  width: 80%!important;
  vertical-align: unset;
  margin-top: 0;
}

.urlGroup .input-group-addon {
  width: 20%;
  display: inline-block;
  vertical-align: middle;
}

.urlGroup a {
  display: inline-block;
  width: 100%;
}

.urlGroup .input-group-addon  button{
  min-width: 100%;
  float: none;
  display: inline-block;
}

.manage-contractors .urlGroup .input-group-addon  button.btn.disabled, 
.manage-contractors .urlGroup .input-group-addon  button.btn:disabled {
  color: #aaa;
  border: 1px solid #aaa;
}

.manage-contractors .urlGroup .input-group-addon  button.btn.disabled, 
.manage-contractors .urlGroup .input-group-addon  button.btn:disabled,
.manage-contractors .urlGroup .input-group-addon  button.btn.disabled:hover, 
.manage-contractors .urlGroup .input-group-addon  button.btn:disabled:hover {
  color: #aaa;
  border: 1px solid #aaa;
  background: none;
}

.quick-add-contractor + form span.input-group.form-inline, 
.quick-add-contractor + form .urlGroup, 
.quick-add-contractor + form .urlGroup span {
  border: 0;
  color: #333;
  cursor: pointer;
  transition: none;
  font-size: 12px;
  padding: 0;
  border-radius: 1px;
  font-weight: 100;
  min-width: auto;
  max-width: initial;
  display: inline-block;
  text-align: center;
}

.quick-add-contractor + form span.input-group.form-inline:hover, 
.quick-add-contractor + form .urlGroup:hover, 
.quick-add-contractor + form .urlGroup span:hover {
  border: 0;
  color: #fff;
  background: none;
  cursor: pointer;
}