/* Base Styles -------------------- */
html {
    font-size: 12px;
}

@include media-breakpoint-up(xl) {
    html {
        font-size: 13px;
    }

}

.has-search .form-control {
    padding-left: 3.375rem;
}

.has-search .form-control-search {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.5rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
    font-size: 1.5rem;
}

.btn {
    border-radius: 20px;
}

main {
    // animation: fadein 1s;
    min-height: 40rem;
}
.color-scheme-2 {
                 min-width: 768px;
                 }

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }

}

.card {
    transition: 0.3s;
}

.card:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0 20px 20px;
}

.card .btn {
    position: relative;
    z-index: 1;
}

.link-overlay,
.link-overlay:focus {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    width: 100%;
    background: none;
    border: none;
    box-shadow: none;
    outline: none;
}

.kb-card-project_add {
    min-height: 200px;
}

.kb-card-template_add {
    min-height: 150px;
}

label {
    color: lighten($secondary, 30%);
}

.nav-pills .nav-link {
    color: inherit;
}

.ghost {
    opacity: 0.3;
    background: $primary !important;
}

.ghost * {
    opacity: 0;
}

.kb-list-item {
    transition: 0.3s;
    position: relative;

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.05) 0 10px 10px;
        z-index: 1;
    }

    .kb-list-item_header {
        position: relative;

        .btn {
            position: relative;
            z-index: 1
        }

    }

    .collapse-arrow {
        position: absolute;
        z-index: 0;
        top: 0;
        height: 4rem;
        right: 0;
        left: 0;
        text-indent: -99999px;
    }

}

.kb-list-item-title {
    font-weight: 500;
}

.kb-list-item-title_edit {
    max-width: 150px;
    width: inherit;
    position: relative;
    z-index: 2;
}

.kb-nav-link {
    .active {
        font-weight: bold;
    }

}

.kb-show-on-edit {
    display: none;
}

.editing {
    .kb-show-on-edit {
        display: inline;
    }

    .kb-hide-on-edit {
        display: none;
    }

}

.kb-list-item_col {
    min-width: 10rem;
    text-align: right;
    padding-right: 2rem;
}

.card-image {
    min-height: 150px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.btn-drag {
    margin-left: -2rem;
    z-index: 3 !important;
}

.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
    animation: fadein 0.3s;
    z-index: 9999;
    overflow: hidden;

    .popup_inner {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background: $white;
        width: 100%;
        max-width: 700px;
        animation: fadein 1s;
        padding: 0 2rem;
        overflow-y: scroll;
        animation: slidein 0.5s;

        @include media-breakpoint-up(lg) {
            padding: 0 4rem;
        }

        .kb-popup_title {
            padding-top: 80px;
        }

    }

    .container-fluid>.row {
        border: none !important;
        padding: 0;
    }
}

.kb-btn_close-popup {
    display: none;
    background-color: rgba(0,0,0, 0.0)!important;

    @include media-breakpoint-up(lg) {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 0;
    }

}

.kb-nav_details {
    background: darken($secondary, 5%);
}

.add-category {
    padding-bottom: 1rem;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}


.label-clickable {
    color: $primary;

    font-weight: bold;

    &:hover {
        cursor: pointer;
    }
}