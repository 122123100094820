/* Custom Mixins -------------------- */
.opacity-0 {
  opacity:0!important;
}
.opacity-1 {
  opacity:0.2!important;
}
.opacity-2 {
  opacity:0.4!important;
}
.opacity-3 {
  opacity:0.6!important;
}
.opacity-4 {
  opacity:.8!important;
}
.opacity-5 {
  opacity:1!important;
}



@keyframes slidein {
	0% {
		 transform:translateX(100%);
	}
  30% {
    transform:translateX(100%);
  }

  100% {
   	transform:translateX(0);
  }
}