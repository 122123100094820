// @import 'some-module';

.side-nav,
.project-menu {
    display: none;
}

.pagination-wrapper {
    position: relative;
    width: 100%;
}

.pagination-wrapper ul {
    margin: 0;
    text-align: center;
    display: inline-block;
    width: 100%;
}

.pagination-wrapper ul li {
    text-align: center;
    display: inline-block;
}

.pagination-wrapper ul li {
    min-width: 20px;
}

.pagination-wrapper ul li.active a {
    color: #fff;
    background: #00b374;
}

.contractor-search {
    max-width: 300px;
}

.pagination-wrapper ul li a {
    border: 1px solid #eee;
    display: block;
    min-width: 30px;
    min-height: 30px;
    margin: 0 2px;
    border-radius: 3px;
    background: #fff;
    color: #00b374;
    padding-top: 5px;
    font-weight: bold;
    font-size: 12px;
}

.pagination-wrapper ul li a:hover {
    text-decoration: none;
    background: #00b374;
    color: #fff;
}

.loading-placeholder {
    width: 100%;
    text-align: center;
    margin-top: 200px;
}

.loading-placeholder h2 {
    font-size: 12px;
}

.react-datepicker-wrapper input {
    display: block;
    width: 100%;
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: $secondary;
    background-color: $gray-200;
    background-clip: padding-box;
    border: 1px solid $gray-200;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
        color: $input-btn-focus-color;
        background-color: $gray-200;
        border-color: $input-focus-border-color;
        outline: 0;
        box-shadow: none;
    }

}

.stats-card {
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
    position: relative;
    background-color: #fff;
    margin-bottom: 30px;
    border: 1px solid #e9eaec;
    border-radius: 3px;
    font-size: 12px;
    padding: 10px;
    float: left;
}

.stats-card.card-size-1of3 {
    width: 32%;
    margin: 10px 0.2%;
}

.stats-card .card-value {
    float: right;
    margin-top: -10px;
    font-size: 24px;
    font-weight: 600;
}

.stats-card .stats-unit,
.stats-card .stats-unit .stats-numbers {
    float: left;
    width: 100%;
}

.stats-card .stats-unit .stats-numbers .stats-title {
    width: 50%;
    float: left;
}

.stats-card .stats-unit .stats-numbers .stats-value {
    width: 50%;
    text-align: right;
    float: right;
}

.stats-card .stats-unit.stats-main-unit {
    margin-bottom: 50px;
}

.stats-card .stats-unit .stats-loadbar {
    min-height: 5px;
    width: 100%;
    display: inline-block;
    background: #eee;
    border-radius: 20px;
    float: left;
    margin-right: 10px;
    margin-bottom: 20px;
}

.stats-card .stats-unit .stats-loadbar .loadbar-fill {
    display: inline-block;
    float: left;
    height: 5px;
    border-radius: 20px;
}

.stats-card .stats-unit.stats-main-unit .stats-loadbar .loadbar-fill {
    background: #f2b62c;
}

.stats-card .stats-unit .stats-loadbar .loadbar-fill {
    background: #72d0f4;
}

.card-center-content .card-value {
    text-align: center;
    width: 100%;
}

.donutchart-track {
    fill: transparent;
    stroke: #DAE2E5;
    stroke-width: 26;
}

.donutchart-indicator {
    fill: transparent;
    stroke: #03B274;
    stroke-width: 26;
    stroke-dasharray: 0 10000;
    transition: stroke-dasharray .3s ease;
}

.donutchart {
    margin: 0 auto;
    border-radius: 50%;
    display: block;
}

.donutchart-text {
    font-family: 'Roboto', Arial;
    fill: #607580;
}

.donutchart-text-val {
    font-size: 1.1rem;
    font-weight: 600;
}

.donutchart-text-percent {
    font-size: 1.1rem;
    font-weight: 600;
}

.donutchart-text-label {
    font-size: 9px;
}

.statistics-block.round-table-wrapper {
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
    position: relative;
    background-color: #fff;
    border: 1px solid #e9eaec;
    border-radius: 3px;
    font-size: 12px;
    padding: 10px;
    float: left;
    margin: 0 0.5% 15px;
    width: 99%;
}

.stats-card.card-size-1of3 {
    width: 32.3%;
    margin: 10px 0.5%;
}

.stats-card .stats-unit .stats-numbers .stats-title {
    width: 60%;
    float: left;
}

.stats-card .stats-unit .stats-numbers .stats-value {
    width: 40%;
    text-align: right;
    float: right;
}

.react-phone-number-input__icon-image {
    vertical-align: top;
}