.react-confirm-alert h1 {
    font-size: 2rem;
    font-weight: bold;

}

.react-confirm-alert {
    font-size: 1rem;
}

.react-confirm-alert .react-confirm-alert-button-group button:nth-of-type(1) {
    background: $primary;
}

.react-confirm-alert .react-confirm-alert-button-group button:nth-of-type(2) {
    background: $light;
    color: inherit;
}

.ReactPasswordStrength,
.ReactPasswordStrength-strength-desc,
.ReactPasswordStrength-input {
    font-size: 1rem;
}

.react-confirm-alert-button-group>button {
    outline: none;
    border: none;
    display: inline-block;
    padding: 6px 20px;
    color: #fff;
    margin-right: 10px;
    border-radius: 20px;
    font-size: 1rem;
    cursor: pointer;

}


.react-confirm-alert-overlay {
    .react-confirm-alert {
        .react-confirm-alert-body {
            font-family: "Ubuntu", sans-serif;
            width: 70vw;
            max-width: 70vw;
            @media (min-width: 768px) {
                    width: 40vw;
                    max-width: 80vw;
            }
            @media (min-width: 992px) {
                    width: 35vw;
                    max-width: 80vw;
            }
            @media (min-width: 1280px) {
                    width: 25vw;
                    max-width: 80vw;
            }
            padding: 2rem;
            text-align: left;
            background: $white;
            border-radius: 10px;
            box-shadow: 0 20px 75px rgba(0, 0, 0, 0.2);
        }
    }
}



.react-confirm-alert-button-group {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
}

.react-confirm-alert-overlay {
    z-index: 9998 !important;
}


.ReactPasswordStrength-input {
    min-width: 100%;
}

.kb-project-picture {
    max-width: 50rem;

    .fileInput {
        display: block;
        width: 100%;
        margin: 1rem 0;
        border: 1px solid #ccc;
        background: #f5f5f5;
        padding: 1rem;
    }
}

.kb-project-picture-placeholder {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 22rem;
    height: 15rem;
    border: 1px solid #ccc;
    background: #F2F2F6;
    max-width: 50rem;
}

.kb-project-picture-preview {
    max-height: 25rem;
    overflow: hidden;
}

.kb-project-picture-preview img {
    width: 100%;
    height: auto;
}


// tabs to pills
.kb-tabs-nav {
    .nav {
        background: $light;
        margin: 0;
        padding: 1rem;
        border: none;
        border-top: 1px solid $gray-200;

        li {
            padding-right: 0.5rem;

            a {
                display: inline-block;
                transition: 0.3s;
                border-radius: $btn-border-radius;
                padding: $btn-padding-y $btn-padding-x;
                border: 1px solid transparent;

                &:hover {
                    background: $white;
                    border-color: $white;
                }
            }

            &.active {
                a {
                    background: $secondary;
                    color: $white;
                    border-color: $secondary;
                }

            }
        }
    }

    .tab-content>.active {
        opacity: 1;
    }
}



.section-container {
    box-shadow: none;
    border: none;
    position: relative;
    background: $white;
    margin-bottom: 30px;

}

.section-container .section-title {
    border-bottom: 1px solid $gray-200;
    padding: 1rem 0;

}

.react-confirm-alert {
    font-family: $font-family-sans-serif;

    .button {
        border-radius: $btn-border-radius;
    }
}

.urlGroup {
    input {
        display: inline-block;
    }
}

.checkbox-inline {
    input {
        margin-right: 7px;
    }
}

.limit-width-75 {
    width: 75px;
    margin-left: 29px;
}

.display-inline {
    display: flex;
}

.kb-category-view-budget,
.kb-subcategory-view-budget {
    max-width: 75px;
    display: inline-block;
    min-width: 65px;
    @media screen and (min-width: 1200px){
        max-width: 120px;
        min-width: 120px;
    }
}

.kb-value-input-wrapper {
    max-width: 100%;
    display: inline-block;
    min-width: 100%;
    margin-right: 0;
    input {
        max-width: 100%;
        display: inline-block;
        min-width: 100%;
    }
}

.kb-heading-subcategory-view-budget {
    max-width: 65px;
    display: inline-block;
    min-width: 65px;
    padding-right: 5px;
    box-sizing: border-box;
    @media screen and (min-width: 1200px){
        max-width: 120px;
        min-width: 120px;
    }
}

.expandable-row .expandable-col {
    z-index: 5;
}
.input-error {
    position: absolute;
    font-size: 10px;
    padding: 0px 1px;
    z-index: 99;
}

.react-datepicker-wrapper {
    width: 100%;
}

.text-light-grey {
    color:rgb(184, 184, 184);
}

.no-max-width {
    max-width: none;
}

.dynamic-input-field-container {
    display: inline-block;
    max-width: 100%;
    width: 100%;
}

.dynamic-input-field {
    width:  100%;
    display: table-cell;
    word-break: break-word;
    max-width: 100%;
}

.empty-spacebar{
    position: relative;
    width: 35px;
}

.absolute-drag-btn{
    position: absolute;
    z-index: 9998;
    top: 0;
    left: 0;
}

.react-datepicker-wrapper {
    width: 100%;
}

.action-btns-limit-width {
    width: 95px;
}

.table th:last-child, .table td:last-child {
    padding-right: .75rem;
}

.table th, .table td {
    padding-right: 0;
}
.react-datepicker-popper {
  z-index: 9998!important;
}

.text-light-grey {
    color:rgb(184, 184, 184);
}

.popup .popup_inner {
    overflow-y: auto;
}

.kb-businessplan-navbar ul.nav.nav-tabs {
    position: relative;
    display: inline-block;
    align-items: center;
    padding: 0.5rem 1rem;
    background-color: $black;
    height: 50px;
    width: 100%;
}

.kb-businessplan-config-total {
    color: #03B274;
}

.kb-businessplan-config-total-row {
    margin-top: 10px;
    padding-top: 10px;
}

.kb-businessplan-section-arrow {
    font-size: small;
}

.kb-businessplan-section-border {
    border-bottom: 1px solid #F2F2F6;
}

.kb-businessplan-section-cell {
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #F2F2F6;
}

.kb-businessplan-navbar ul.nav.nav-tabs li {
    display: inline-block;
}

.kb-businessplan-ibcode {
    padding: 0 10px;
    padding-left: 0;
    box-sizing: border-box;
    width: 100px;
}

.kb-businessplan-navbar ul.nav.nav-tabs li.active {
    display: inline-block;
}


.kb-businessplan-navbar ul.nav.nav-tabs li a {
    display: inline-block;
    transition: 0.3s;
    padding: 0.5rem 1.75rem;
    color: white;
}

// tabs to pills
.kb-tabs-nav {
    .kb-businessplan-navbar {
        ul.nav {
            background: $black;

            li {
                a {
                    background: $black;
                    color: $white;
                    font-size: 1rem;

                    &:hover,
                    &:active,
                    &:focus {
                        color: $black;
                        background: $white;
                        font-size: 1rem;
                    }
                }
            }

            li.active {
                a {
                    font-size: 1rem;
                    color: $black;
                    background: $white;
                }
            }
        }
    }
}

.kb-tabs-nav.bg-dark,
.kb-businessplan-navbar.bg-dark {
    .nav-link, .nav-pills .show > .nav-link {
        color: #F8F8FA;
        background-color: #3C434A;
        font-weight: 700;
    }
    .nav-link.active, .nav-pills .show > .nav-link {
        color: #3C434A;
        background-color: #F8F8FA;
        font-weight: 700;
    }
}

.tabs-to-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
}
.tabs-to-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}


.black-nav-tabs {
    position: relative;
    ul.nav {
        background: $black;
        display: block;
        text-align: right;
        li {
            display: inline-block;
            a {
                background: $black;
                color: $white;
                font-weight: 700;
                font-size: $fontSize3;
                &:hover,
                &:active,
                &:focus {
                    color: $black;
                    background: $white;
                    font-size: $fontSize3;
                }
            }
        }
        li.active {
            a {
                font-size: $fontSize3;
                color: $black;
                background: $white;
            }
        }
    }
}

.pl-200 {
    ul.nav {
        padding-left: 200px;
    }
}

.forcast-title {
    position: absolute;
    color: $white;
    font-size: $fontSize5;
    padding: 8px;
}

.calendar-range {
    font-size: $fontSize2;
}

.bg-ghost {
    background: $ghost;
}
.bg-quartz {
    background: $quartz;
}
.bg-whisper {
    background: $whisper;
}

.kb-real-forecast-title {
    font-size: $fontSize3;
}
.kb-real-forecast-value {
    font-size: $fontSize4;
    font-weight: bold;
    margin-left: 10px;
    padding-bottom: 2px;
}

.kb-real-time-budget-color {
    background-color: #F2F2F6;
}

.kb-real-time-budget-navbar ul.nav.nav-tabs {
    background: $white;
}

.kb-real-time-budget-border-line
{
    border-bottom: 3px solid $white;
}
.kb-rtb-configs-border-line .kb-table .row {
    border-bottom: 2px solid $white;
}

.kb-real-time-budget-overflow {
    border-radius: 40px;
    background-color: #f7e2e2;
    color:black;
}

.PhoneInputInput,
.PhoneInputInputInput,
.PhoneInputInput.PhoneInputInput--focus .PhoneInputInputInput,
.PhoneInputInput input,
.PhoneInputInput--focus input,
.PhoneInputInput input:focus,
.PhoneInputInput input:focus-visible {
    border: none;
    border-bottom: 1px solid #ddd;
    outline: none;
}

.btn,
h6, .h6 {
    font-size: 1rem;
}

.btn i {
    font-size: $fontSize2;
}

main {
    // animation: fadein 1s;
    min-height: 30rem;
}

.font-size-2 {
    font-size: $fontSize2;
}
.font-size-3 {
    font-size: $fontSize3;
}
.font-size-4 {
    font-size: $fontSize4;
}
.font-size-5 {
    font-size: $fontSize5;
}
.font-size-6 {
    font-size: $fontSize6;
}
.font-size-7 {
    font-size: $fontSize7;
}
.font-size-8 {
    font-size: $fontSize8;
}

.min-w-85 {
    min-width: 85px;
}

.kb-rtb-forecast-initial-actual {
    display: inline-flex;
}

.kb-rtb-forecast-initial-actual-value {
    margin-left: 10px;
    padding-bottom: 2px;
}

.kb-invoices-export {
    background-color: #343a40;
    &:focus,
    &:active,
    &:hover,
    &:after,
    &:before
        {
        background-color: #343a40;
        }
}

.h-1rem {
    height: 1rem;
}

.error-borders {
    border: 1px solid #f00;
}

.mx--1{
    margin-left: -1px;
    margin-right: -1px;
}

button.btn.url-button {
    min-width: 100%;
}

button:focus {
    border: 0;
    outline: 0px auto -webkit-focus-ring-color;
}


.input-tooltip {
    position: absolute;
    background: #333;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    bottom: 40px;
    display: none;
    left: 0;
    word-break: break-word;
    z-index: 99;
}

.input-tooltip-container {
    position: relative;
}
.input-tooltip-container:hover .input-tooltip {
    display: inline-block;
}

.input-tooltip-container:hover .input-tooltip::after {
    position: absolute;
    border-left: 7px solid transparent;
    border-right: 4px solid transparent;
    border-top: 13px solid #333;
    bottom: -13px;
    left: 23px;
    width: 0px;
    height: 0px;
    content: "";
}

.cursor-pointer {
    cursor: pointer;
}

.user-select-none {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus,
.btn-link:not(:disabled):not(.disabled):active:focus, 
.btn-link:not(:disabled):not(.disabled).active:focus, 
.show > .btn-link.dropdown-toggle:focus,
.btn-primary:focus, .btn-primary.focus,
.btn-link:focus, .btn-link.focus {
    box-shadow: none;
    border: 1px solid transparent;
    outline: none;
}

.popup .popup_inner.variable-width-side-modal {
    @media (min-width: 768px) {
        max-width: 80vw;
    }
    @media (min-width: 992px) {
            max-width: 50vw;
    }
    @media (min-width: 1280px) {
            max-width: 35vw;
    }
}
.btn-primary:focus, .btn-primary.focus, .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: none;
    border: 1px solid transparent;
    outline: none;
}

.kb-list-item {
    .collapse-arrow {
	    height: 100%;
	}
}

.text-medium-grey {
    color: #9e9e9e;
}
  
.bg-light-grey {
    background: #ededf0;
}
.form-control.no-focus:focus {
    border-color: transparent;
}
.form-control.no-focus.text-medium-grey:focus {
    color: #adadb4;
}
.h-115rem {
    height: 1.15rem;
}
.preloader {
    position: fixed;
    height: 99999px;
    width: 100%;
    overflow: hidden;
    background: rgba(255,255,255, 0.9);
    z-index: 9999;
    top: 0;
    left: 0;
    display: none;
}

.preloader.visible-loader {
    display: inline-block;
}

.loading-bar {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -68px;
    margin-left: -68px;
    z-index: 999999;
    width: 100%;
    height: 100%;
    overflow: visible;
}

.loading-element {
    height: 120px;
    width: 120px; 
    border: 10px solid #ededf0;
    border-top: 10px solid #03B274;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: 2s spin linear infinite;
}

.text-load {
    width: 120px;
}

.loading-logo {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 26px;
    bottom: 0;
    left: 30px;
    right: 0;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.popup {
    z-index: 9998;
}

.react-datepicker-wrapper input:focus {
    color: inherit;
}

.tscroll {
    max-width: 100%;
    overflow-x: auto;
}

.tscroll table th:first-child {
    position: sticky;
    left: 0;
    background: #fff;
    margin-bottom: 15px;
    min-width: 20vw;
}

.tscroll table thead th:first-child {
    background: #343a40;
}

