.kb-table_permissions {
    overflow: auto;

    table {
        table-layout: fixed;
    }

    thead th {
        vertical-align: middle;
    }

    tr:hover {
        font-weight: bold;
    }

    tr>* {
        width: 8rem;
        vertical-align: middle;
        text-align: center;
        border: none;
    }

    tr>*:first-child {
        position: -webkit-sticky;
        position: sticky;
        text-align: left;
        left: 0;
        min-width: 15rem;
        width: 15rem;
        z-index: 1;

        @include media-breakpoint-up(md) {
            width: 20rem;
        }

        @include media-breakpoint-up(xl) {
            width: 25rem;
        }

    }

    tr>*:first-child::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: $white;
        z-index: -1;
        border-right: 1px solid $gray-200;
    }

    label {
        font-size: 1.5rem;
        margin: 0;

        input[type=checkbox]:disabled,
        input[type=radio]:disabled {
            color: transparent;
        }

    }

    .kb-marked,
    .kb-marked::before {
        background-color: $gray-100 !important;
    }

}

.kb-table_permissions_single {

    tr,
    td {
        text-align: left;
    }

    label {
        margin-left: 3rem;
    }

}


.fancy label,
label.fancy {
    display: inline-flex;
    align-items: baseline;

    input[type=checkbox],
    input[type=radio] {
        position: relative;
        appearance: none;
        font-size: inherit;
        width: 1em;
        margin: 0;
        color: inherit;
        outline: none;
        box-shadow: none;
        font-family: "Font Awesome 5 Free";
        transition: 300ms ease-out;

        &::after {
            content: '\f111';
            // circle
            display: inline-block;
            text-align: center;
            width: 1em;
        }

        &:checked::after {
            font-weight: 900;
        }

        &:active {
            transform: scale(.6);
        }

        +span {
            margin-left: .35em;
        }

    }

    input[type=checkbox]:disabled,
    input[type=radio]:disabled {
        border: none;
        outline: none;
    }

    input[type=checkbox]:checked::after {
        content: '\f058';
        // check-circle
        color: $primary;
    }

    input[type=radio]:checked::after {
        content: '\f192';
        // dot-circle
        color: $primary;
    }

}

.fancy.square label {
    input[type=checkbox]:after {
        content: '\f0c8';
        // square
    }

    input[type=checkbox]:checked::after {
        content: '\f14a';
        // check-square
    }

}

.kb-table {
    .row {
        margin: 0;
        padding: 0 1.5rem;
        align-items: center;
        border-bottom: 1px solid $gray-200;
        position: relative;

        @include media-breakpoint-down(md) {
            border-bottom: 1px solid $gray-200;
            margin-bottom: 1rem;
        }

    }

    .col,
    .col.table-col {
        padding: 1rem;
        text-overflow: ellipsis;

        @include media-breakpoint-down(md) {
            display: block;
            flex: none;
            text-align: right;
            padding: 0.5rem 0;

            &::before {
                content: attr(data-label);
                display: inline-block;
                float: left;
                opacity: .7;
                font-weight: 400;
            }

        }

    }

    .col.table-col {
        text-align: right;

        @include media-breakpoint-up(lg) {
            width: 9.5%;
        }

    }

    .kb-col-actions {
        max-width: 8rem;
        text-align: right;
        padding-right: 0;
        padding-left: 0;

        @include media-breakpoint-down(md) {
            position: absolute;
            right: 0;
            top: 0;
        }

    }

    .kb-col-name {
        @include media-breakpoint-down(md) {
            text-align: left;
            font-size: 1.25rem;

            &::before {
                display: none;
            }

        }

    }

    .collapse-arrow {
        position: absolute;
        left: 0;
        position: absolute;
        background: $primary;
        color: $white;
        left: 0;
        line-height: 1;
        font-size: 0.75rem;
        padding: 0.75rem 0.5rem;
        border-radius: 1rem;
    }

    .kb-thead {
        line-height: 1.2;

        @include media-breakpoint-down(md) {
            display: none;
        }

    }

    .kb-tbody {
        .row {
            padding: 0 1.5rem;
            position: relative;

            @include media-breakpoint-down(md) {
                padding: 1rem 1.5rem;
            }

        }

    }

    &.kb-table-lg {
        .col {
            @include media-breakpoint-down(up) {
                padding-top: 1.5rem;
                padding-bottom: 1.5rem;
            }

        }

    }

}

.kb-chevron {
    .collapse-arrow {
        position: absolute;
        position: absolute;
        background: #03B274;
        color: #fff;
        left: 1rem;
        line-height: 1;
        font-size: 0.75rem;
        padding: 0.75rem 0.5rem;
        border-radius: 1rem;
        text-indent: 0;
        right: inherit;
        height: inherit;
        top: 1rem;
    }

    .kb-list-item-title {
        padding-left: 3rem;
    }
}

// table setting by section

.kb-table {
    width: 100%;

    // kb-table_users
    &_users {
        .kb-col-id {
            max-width: 3rem;
            padding-right: 0;
            padding-left: 0;

            @include media-breakpoint-down(md) {
                display: none;
            }

        }

        .kb-col-actions {
            max-width: 8rem;
            text-align: right;
            padding-right: 0;
            padding-left: 0;

            @include media-breakpoint-down(md) {
                position: absolute;
                right: 0;
                top: 0;
            }

        }

        .kb-col-name {
            @include media-breakpoint-down(md) {
                text-align: left;
                font-size: 1.25rem;

                &::before {
                    display: none;
                }

            }

        }

    }

}


.current-month {
    .card {
        box-shadow: rgba(0, 0, 0, 0.1) 0 20px 20px;
    }
    .kb-month-box, 
    .d-table {        
        border-left: 2px solid #ddd;
        border-right: 2px solid #ddd;
    }
}