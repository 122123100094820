   /*!
 * Bootstrap Reboot v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
 *,
 *::before,
 *::after {
     box-sizing: border-box;
 }

 html {
     font-family: sans-serif;
     line-height: 1.15;
     -webkit-text-size-adjust: 100%;
     -webkit-tap-highlight-color: rgba(60, 67, 74, 0);
 }

 article,
 aside,
 figcaption,
 figure,
 footer,
 header,
 hgroup,
 main,
 nav,
 section {
     display: block;
 }

 body {
     margin: 0;
     font-family: "Ubuntu", sans-serif;
     font-size: 1rem;
     font-weight: 400;
     line-height: 1.5;
     color: #3C434A;
     text-align: left;
     background-color: #F8F8FA;
 }

 [tabindex="-1"]:focus {
     outline: 0 !important;
 }

 hr {
     box-sizing: content-box;
     height: 0;
     overflow: visible;
 }

 h1,
 h2,
 h3,
 h4,
 h5,
 h6 {
     margin-top: 0;
     margin-bottom: 0.5rem;
 }

 p {
     margin-top: 0;
     margin-bottom: 1rem;
 }

 abbr[title],
 abbr[data-original-title] {
     text-decoration: underline;
     -webkit-text-decoration: underline dotted;
     text-decoration: underline dotted;
     cursor: help;
     border-bottom: 0;
     text-decoration-skip-ink: none;
 }

 address {
     margin-bottom: 1rem;
     font-style: normal;
     line-height: inherit;
 }

 ol,
 ul,
 dl {
     margin-top: 0;
     margin-bottom: 1rem;
 }

 ol ol,
 ul ul,
 ol ul,
 ul ol {
     margin-bottom: 0;
 }

 dt {
     font-weight: 700;
 }

 dd {
     margin-bottom: .5rem;
     margin-left: 0;
 }

 blockquote {
     margin: 0 0 1rem;
 }

 b,
 strong {
     font-weight: bolder;
 }

 small {
     font-size: 80%;
 }

 sub,
 sup {
     position: relative;
     font-size: 75%;
     line-height: 0;
     vertical-align: baseline;
 }

 sub {
     bottom: -.25em;
 }

 sup {
     top: -.5em;
 }

 a {
     color: #3C434A;
     text-decoration: none;
     background-color: transparent;
 }

 a:hover {
     color: #1a1d20;
     text-decoration: none;
 }

 a:not([href]):not([tabindex]) {
     color: inherit;
     text-decoration: none;
 }

 a:not([href]):not([tabindex]):hover,
 a:not([href]):not([tabindex]):focus {
     color: inherit;
     text-decoration: none;
 }

 a:not([href]):not([tabindex]):focus {
     outline: 0;
 }

 pre,
 code,
 kbd,
 samp {
     font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
     font-size: 1em;
 }

 pre {
     margin-top: 0;
     margin-bottom: 1rem;
     overflow: auto;
 }

 figure {
     margin: 0 0 1rem;
 }

 img {
     vertical-align: middle;
     border-style: none;
 }

 svg {
     overflow: hidden;
     vertical-align: middle;
 }

 table {
     border-collapse: collapse;
 }

 caption {
     padding-top: 0.75rem;
     padding-bottom: 0.75rem;
     color: #6c757d;
     text-align: left;
     caption-side: bottom;
 }

 th {
     text-align: inherit;
 }

 label {
     display: inline-block;
     margin-bottom: 0.5rem;
 }

 button {
     border-radius: 0;
 }

 button:focus {
     outline: 1px dotted;
     outline: 5px auto -webkit-focus-ring-color;
 }

 input,
 button,
 select,
 optgroup,
 textarea {
     margin: 0;
     font-family: inherit;
     font-size: inherit;
     line-height: inherit;
 }

 button,
 input {
     overflow: visible;
 }

 button,
 select {
     text-transform: none;
 }

 select {
     word-wrap: normal;
 }

 button,
 [type="button"],
 [type="reset"],
 [type="submit"] {
     -webkit-appearance: button;
 }

 button:not(:disabled),
 [type="button"]:not(:disabled),
 [type="reset"]:not(:disabled),
 [type="submit"]:not(:disabled) {
     cursor: pointer;
 }

 button::-moz-focus-inner,
 [type="button"]::-moz-focus-inner,
 [type="reset"]::-moz-focus-inner,
 [type="submit"]::-moz-focus-inner {
     padding: 0;
     border-style: none;
 }

 input[type="radio"],
 input[type="checkbox"] {
     box-sizing: border-box;
     padding: 0;
 }

 input[type="date"],
 input[type="time"],
 input[type="datetime-local"],
 input[type="month"] {
     -webkit-appearance: listbox;
 }

 textarea {
     overflow: auto;
     resize: vertical;
 }

 fieldset {
     min-width: 0;
     padding: 0;
     margin: 0;
     border: 0;
 }

 legend {
     display: block;
     width: 100%;
     max-width: 100%;
     padding: 0;
     margin-bottom: .5rem;
     font-size: 1.5rem;
     line-height: inherit;
     color: inherit;
     white-space: normal;
 }

 progress {
     vertical-align: baseline;
 }

 [type="number"]::-webkit-inner-spin-button,
 [type="number"]::-webkit-outer-spin-button {
     height: auto;
 }

 [type="search"] {
     outline-offset: -2px;
     -webkit-appearance: none;
 }

 [type="search"]::-webkit-search-decoration {
     -webkit-appearance: none;
 }

 ::-webkit-file-upload-button {
     font: inherit;
     -webkit-appearance: button;
 }

 output {
     display: inline-block;
 }

 summary {
     display: list-item;
     cursor: pointer;
 }

 template {
     display: none;
 }

 [hidden] {
     display: none !important;
 }

 html {
     font-size: 13px;
 }

 @media only screen and (max-width:768px) {
     html {
         font-size: 12px;
     }

 }


 .kb-login .form-control {
     margin-bottom: 2rem;
     display: block;
     width: 100%;
     height: calc(1.5em + 1rem + 2px);
     padding: 0.5rem 1rem;
     font-size: 1rem;
     font-weight: 400;
     line-height: 1.5;
     color: #3C434A;
     background-color: #F2F2F6;
     background-clip: padding-box;
     border: 1px solid #F2F2F6;
     border-radius: 0.25rem;
     transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
 }

 .kb-login .form-control:focus {
     color: #3C434A;
     background-color: #F2F2F6;
     border-color: #a7a7c2;
     outline: 0;
     box-shadow: none;
 }

 .kb-login label {
     display: block;
 }

 .kb-login .btn, .btn-primary {
     min-width: 8rem;
     color: #fff;
     background-color: #03B274;
     border-color: #03B274;
     display: inline-block;
     font-weight: 400;
     text-align: center;
     vertical-align: middle;
     -webkit-user-select: none;
     -moz-user-select: none;
     -ms-user-select: none;
     user-select: none;
     border: 1px solid transparent;
     padding: 0.5rem 1.75rem;
     font-size: 1rem;
     line-height: 1.5;
     border-radius: 20px;
     transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
 }

 .no-min-width.btn-primary{
  min-width: auto;
 }

 .kb-login .btn:hover, .btn-primary:hover {
     color: #fff;
     background-color: #028c5b;
     border-color: #028053;
 }

 .kb-login .kb-nav_logo {
     position: relative;
     top: 5vw;
     left: 10vw;
     padding-bottom: 4rem;
     display: inline-block;
 }

 .kb-login h1 {
     font-size: 2rem;
     font-weight: 400;
     padding: 2rem 0 3rem;
 }

 @media only screen and (max-height:600px) {
     .kb-login h1 {
         padding-bottom: 2rem;
         padding-top: 3rem;
     }

 }

 .kb-login_form {
     width: 100%;
     max-width: 25rem;
     left: 10vw;
 }

 @media only screen and (max-height:600px) {
     .kb-login_form {
         max-width: 50rem;
         left: inherit;
         right: inherit;
         padding-left: 10vw;
         padding-right: 10vw;

         padding-bottom: 10rem;
     }

 }

 @media only screen and (min-height:600px) {
     .kb-login_form {
         position: absolute;
         top: 50%;
         transform: translateY(-60%);
         left: 10vw;
     }

     .kb-login .kb-nav_logo {
         position: absolute;
         top: 5vw;
         left: 10vw;
     }

 }

 @media only screen and (max-width:768px) {
     .kb-login_form {
         max-width: 40rem;
         left: inherit;
         right: inherit;
         padding-left: 10vw;
         padding-right: 10vw;

     }

 }



 .kb-login_image {
     position: fixed;
     top: 5vw;
     right: 5vw;
     bottom: 5vw;
     width: 40vw;
     background: url('../../images/bg.jpg') center center no-repeat;
     background-size: cover;
 }

 @media only screen and (max-width:768px) {
     .kb-login_image {
         top: inherit;
         position: fixed;
         bottom: 0;
         width: 100%;
         height: 4.5rem;
     }
 }


 .kb-error-message {
     display: none;
     border-color: #cc0000;
     color: #cc0000;
     padding: 1rem;
     margin-bottom: 1.5rem;
     width: 100%;
     font-size: 12px;
     background: #ffe8e8;
 }

 .kb error-message i {
     margin-right: 1rem;
 }



/* HOMEPAGE CSS */


/* ---------------------------------------------------- HOMEPAGE */

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.hp-wrapper-top {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: relative;
  background-image: url('../../images/woman-user.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 0;
  margin: 0 auto 0 auto;
  width: 100%;
  height: 75%;
}

.hp-overlay {
  background-color: rgba(11, 168, 113, 0.93);
  height: 100%;
  width: 100%;
}

.hp-wrapper-top::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 0;
  left: 0;
  bottom: 0;
  z-index: 101;
  border-top: 155px solid transparent;
  border-bottom: 0 solid transparent;
  border-right: 100vw solid #fff;
}

.hp-header {
  height: 75px;
  padding: 0px;
  position: fixed;
  z-index: 999;
  background: #fff;
  width: 100%;
  top: 0;
}

.hp-header .hp-logo img {
  margin: 10px 0 0 4rem;
}

.hp-header a.hp-login {
  float: right;
  border: 1px solid #00b374;
  border-radius: 15px;
  padding: 5px 20px;
  font-size: 12px;
  color: #00b374;
  margin: 23px 4rem 0 0;
} 

/* generic stuff */
a, button {
  transition: background-color 0.3s ease;
}

.hp-header a.hp-login:hover {
  background: #00b374;
  color: #fff !important;
  cursor: pointer;
  text-decoration: none;
}

.hp-devices {
  position: absolute;
  bottom: -70px;
  right: 0;
  z-index: 102;
  float: right;
  margin-right: 30px;
  max-width: 700px;
}

.hp-content {
  display: inline-block;
  padding-top: 100px;
}

.hp-content h1 {
  margin: 120px 0 0 90px;
  max-width: 70%;
  color: #fff;
  font-size: 38px;
}

.hp-content h2 {
  margin: 30px 0 0 90px;
  max-width: 50%;
  color: #eee;
  line-height: 1.6;
  font-size: 18px;
}

.hp-content a.hp-link {
  float: left;
  border: 1px solid #fff;
  border-radius: 15px;
  padding: 5px 20px;
  font-size: 12px;
  color: #fff;
  margin: 35px 40px 0 90px;
}

.hp-content a.hp-link:hover {
  background: #fff;
  color: #00b374 !important;
  cursor: pointer;
  text-decoration: none;
}

.features {
  width: 980px;
  margin: 50px auto 0 auto;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 150px;
}

.section-title {
  text-align: center;
  margin-bottom: 80px;
  z-index: 99;
  position: relative;
}

.section-title h4 {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  position: relative;
  display: inline-block;
}

.section-title h4:before {
  content: '';
  display: block;
  position: absolute;
  width: 30px;
  height: 1px;
  left: -39px;
  background: #000;
  top: 50%;
}

.section-title h4:after {
  content: '';
  display: block;
  position: absolute;
  width: 30px;
  height: 1px;
  right: -39px;
  background: #000;
  top: 50%;
}

.section-title h2 {
  margin: 0;
  text-transform: uppercase;
  font-size: 35px;
  font-weight: 800;
  position: relative;
}

.feature-1,
.feature-2,
.feature-3 {
  position: relative;
  margin: 20px 0;
}

.features i::before {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  width: auto;
  font-weight: 900;
  font-size: 50px;
  color: #00b374;
}

.features .feature-title {
  font-weight: 500;
  font-size: 16px;
  margin-top: 10px
}

.features .feature-description {
  font-weight: 300;
  font-size: 12px;
  max-width: 230px;
  margin: 10px auto 0 auto;
}

.feature-1 i::before {
  content: "\f5fd";
}

.feature-2 i::before {
  content: "\f02b";
}

.feature-3 i::before {
  content: "\f0c0";
}

.feature-wrapper {
  -webkit-box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035), 0 7px 18px rgba(0, 9, 128, 0.05);
  -moz-box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035), 0 7px 18px rgba(0, 9, 128, 0.05);
  -ms-box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035), 0 7px 18px rgba(0, 9, 128, 0.05);
  -o-box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035), 0 7px 18px rgba(0, 9, 128, 0.05);
  box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035), 0 7px 18px rgba(0, 9, 128, 0.05);
  padding: 50px 50px 30px 50px;
  min-height: 300px;
}

.how-it-works {
  width: 980px;
  margin: 0 auto;

}

.how-it-works-line {
  margin: 50px 0 200px 0
}

.how-it-works-line img {
  max-width: 500px;
  text-align: center;
}

.how-it-works-line h3 {
  font-weight: 200;
  font-size: 40px;
  margin-top: 50px;
}

.how-it-works-line h5 {
  font-weight: 200;
  font-size: 20px;
}

.hp-wrapper-bottom .hp-content {
  width: 100%;
  padding-bottom: 100px;
}

.hp-wrapper-bottom .hp-content h4 {
  color: #fff;
  opacity: 0.8
}

.hp-wrapper-bottom .hp-content h4::before,
.hp-wrapper-bottom .hp-content h4::after {
  background: #fff;
  opacity: 0.8
}

.hp-wrapper-bottom .hp-content h2 {
  max-width: 100%;
  opacity: 0.8
}  

.clients,
.clients ul {
  width: 100%;
  text-align: center;
}

.clients ul li {
  list-style: none;
  display: inline-block;
  margin: 0 50px;
}

.clients ul li img {
  max-width: 100px;
}

.hp-wrapper-bottom {
  width: 100%;
  top: 0;
  left: 0;
  position: relative;
  background-image: url('../../images/handshake.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 0;
  margin: 0 auto 0 auto;
}

.get-in-touch {
  padding: 80px 0;
}

.get-in-touch .contact-line-1,
.get-in-touch .contact-line-2 {
  text-align: center;
  font-weight: 100;
}

.get-in-touch .contact-line-1::before {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  width: auto;
  font-weight: 800;
  font-size: 17px;
  color: #00b374;
  content: "\f0e0";
  margin-right: 10px;
}

.get-in-touch .contact-line-2::before {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  width: auto;
  font-weight: 800;
  font-size: 17px;
  color: #00b374;
  content: "\f095";
  margin-right: 10px;
}


.hp-devices img {
  max-width: 100%;
}

.navbar-brand img,
.hp-logo img {
  max-width: 150px;
}

.main-error {
              position: absolute;
  top: 40vh;
  max-width: 600px;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
  padding: 4rem;
  }

  .two-btn-col {
    width: 100px;
  }

  .grey-active-pill a.nav-link {
    background: #F8F8FA;
    color: #222;
  }
  .grey-active-pill a.nav-link.active {
    background: rgb(221, 221, 227);
    font-weight: 700;
    color: #222;
  }

  .text-grey {
    color: #999;
  }

  .text-grey2 {
    color: #7b7b7b;
  }

  .breadcrumb-item.active {
    color: #222;
    font-weight: 700;
}

.user-select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.history-field-width {
  width: 50%;
  @media only screen and (min-width:768px) {
    width: calc(100% / 7);
  }
}

.history-pagination {
  position: absolute;
  left: 0;
  bottom: 0;
}


.dropdown-select-menu {
  position: absolute;
  background: #fff;
  z-index: 99;
  width: 250px;
  border: 1px solid #dfd4d4;
}

.dropdown-select-menu .dropdown-item {
  border: 1px solid #dfd4d4;
  cursor: pointer;
  &:last-child {
    border: none;
  }
}

.select-style-button.btn-light:not(:disabled):not(.disabled):active,
.select-style-button.btn-light:not(:disabled):not(.disabled).active,
.show > .select-style-button.btn-light.dropdown-toggle {
  background: #fff;
  background-color: #fff;
}

button.select-style-button.dropdown-toggle {
  border-radius: 5px;
  background: #fff;
  border: 1px solid #f2f2f6;
  width: 250px;
  border-right: 17px solid #fff;
  position: relative;
  text-align: left;
  overflow: hidden;
  &::after {
    margin-left: 2.255em;
    position: absolute;
    right: 0;
    top: 16px;
  }
  &::before {
    position: absolute;
    right: 0;
    top: 0;
    background: #fff;
    width: 23px;
    height: 100%;
    content: "";
  }
  &:focus {
    background: #fff;
    background-color: #fff;
  }
  &:active {
    background: #fff;
    background-color: #fff;
    border-right: 23px solid transparent;
    &::after {
      right: 16px;
    }
    &::before {
      background: #fff;
      width: 39px;
    }
  }
}
