.vertical-line {
  background: #f2f2f6;
  width: 2px;
}

.align-center {
  justify-content: center;
  display: flex;
}

.project-img {
  width: 22rem;
  height: 15rem;
}

.spinner {
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  border: 4px solid #f2f2f6;
  border-top: 4px solid #03B274;
  animation: spin 1s linear infinite;
}

.spinner-contracts {
	margin-bottom: 8px;
	width: 1.4em;
  	height: 1.4em;
}

.spinner-smaller {
	width: 0.9em;
	height: 0.9em;
	margin-bottom: 4.5px;
}

.spinner-header {
  position: relative;
  top: -20%;
}

.spinner-total-ib {
	width: 1.2em;
	height: 1.2em;
	margin-left: 10px;
	margin-bottom: 2px;
}

.span-spinner {
  position: absolute;
  right: -15%;
  top: 25%;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.container-inline-div {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.inline-div {
  margin-right: 1rem;
}

.align-right {
  margin-left: auto !important;
}

.arrow {
	--arrow-size: 1em;
	--arrow-color: #03B274;
	--arrow-color-hover: rgba(255,255,255,0.5);
	position: relative;
	display: inline-flex;
	vertical-align: middle;
	width: var(--arrow-size);
	height: var(--arrow-size);
	overflow: hidden;
  cursor: pointer;
  margin-left: 1rem;
	
	&::after {
		content: '';
		position: absolute;
		top: 50%;
		box-sizing: border-box;
		display: inline-block;
		width: calc( var(--arrow-size) * .7071);
		height: calc( var(--arrow-size) * .7071);
		border-top: calc( var(--arrow-size) / 5 ) solid var(--arrow-color);
		transition: all 150ms ease-in-out;
	}
	
	&.left {
		&::after {
			left: calc( var(--arrow-size) / 5 );
			border-left: calc( var(--arrow-size) / 5 ) solid var(--arrow-color);
			transform-origin: top left;
			rotate: -45deg;
		}
	}

	&.right {
		&::after {
			right: calc( var(--arrow-size) / 5 );
			border-right: calc( var(--arrow-size) / 5 ) solid var(--arrow-color);
			transform-origin: top right;
			rotate: 45deg;
		}
	}
}

.arrow-icons {
  margin-left: 1rem;
}

.arrow-disabled {  
	--arrow-size: 1em;
	--arrow-color: #6c757d;
	--arrow-color-hover: rgba(255,255,255,0.5);
	position: relative;
	display: inline-flex;
	vertical-align: middle;
	width: var(--arrow-size);
	height: var(--arrow-size);
	overflow: hidden;
  margin-left: 1rem;
	
	&::after {
		content: '';
		position: absolute;
		top: 50%;
		box-sizing: border-box;
		display: inline-block;
		width: calc( var(--arrow-size) * .7071);
		height: calc( var(--arrow-size) * .7071);
		border-top: calc( var(--arrow-size) / 5 ) solid var(--arrow-color);
		transition: all 150ms ease-in-out;
	}
	
	&.left {
		&::after {
			left: calc( var(--arrow-size) / 5 );
			border-left: calc( var(--arrow-size) / 5 ) solid var(--arrow-color);
			transform-origin: top left;
			rotate: -45deg;
		}
	}

	&.right {
		&::after {
			right: calc( var(--arrow-size) / 5 );
			border-right: calc( var(--arrow-size) / 5 ) solid var(--arrow-color);
			transform-origin: top right;
			rotate: 45deg;
		}
	}
}