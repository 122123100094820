.popup-inner {
  width: 100%;
}

@media only screen and (min-width: 500px) {
  .popup-inner {
    width: 400px;
  }
}

.popup_inner .btn:nth-of-type(1).btn-close {
  display: inline-block;
  text-align: center;
  width: auto;
  border-radius: 2px;
  margin-top: 40px;
  color: #666;
  text-decoration: none;
  text-transform: capitalize;
  font-size: 11px;
  border: 1px solid #666;
  min-width: 100px;
  background: transparent;
}

.popup_inner .btn:nth-of-type(1):hover.btn-close {
  cursor: pointer;
  background: #666;
  color: #fff;
}