.dropdown-role__container {
    align-items: center;
}

.dropdown-role__input {
    width: 95%;
}

.dropdown-role__icon {
    width: 5%;
    margin-bottom: 1rem;
}